@charset "UTF-8";
/*　トップ　ここから　*/
.main--home .mv {
  margin-bottom: 105px;
}
.main--home .mv__slide img {
  width: 100%;
}
@media (max-width: 1024px) {
  .main--home .mv {
    margin-bottom: 81px;
  }
}
.main--home .slick-dots {
  bottom: 18px;
}
.main--home .slick-dots li {
  width: 40px;
  height: 3px;
}
.main--home .slick-dots li button {
  width: 100%;
  height: 100%;
  padding: 0;
  background: #D8D8D8;
}
.main--home .slick-dots li button:before {
  display: none;
}
.main--home .slick-dots li.slick-active button {
  background: #707070;
}
@media (max-width: 1024px) {
  .main--home .slick-dots {
    bottom: 8px;
  }
  .main--home .slick-dots li {
    width: 30px;
  }
}
.main--home .news {
  width: 1266px;
  max-width: 90%;
  margin: 0 auto 100px;
}
@media (max-width: 1024px) {
  .main--home .news {
    max-width: calc(100% - 4px);
  }
}
.main--home .news__ttl {
  margin-bottom: 43px;
  text-align: center;
}
.main--home .news__ttl .en {
  font-size: 3.8rem;
  font-family: "Lora", "Noto Serif JP", serif;
  font-weight: 700;
}
.main--home .news__ttl .jp {
  display: block;
  margin-top: 7px;
  font-size: 1.2rem;
  font-family: "Noto Serif JP", serif;
  letter-spacing: 0.05em;
}
@media (max-width: 1024px) {
  .main--home .news__ttl .en {
    font-size: 3rem;
  }
  .main--home .news__ttl .jp {
    font-size: 1.2rem;
  }
}
.main--home .news .btn-row {
  text-align: center;
  margin-top: 58px;
}
@media (max-width: 1024px) {
  .main--home .news .btn-row {
    margin-top: 37px;
  }
}
.main--home .banner {
  margin-bottom: 100px;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.main--home .banner__link {
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 1024px) {
  .main--home .banner {
    padding: 0 5%;
    margin-bottom: 80px;
  }
}

/*　トップ　ここまで　*/
/*　NEWS　ここから　*/
@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.main--news {
  margin-top: 23px;
}
@media (max-width: 1024px) {
  .main--news {
    margin-top: 21px;
  }
}
.main--news .under-ttl {
  margin-bottom: 73px;
}
@media (max-width: 1024px) {
  .main--news .under-ttl {
    margin-bottom: 37px;
  }
}
.main--news .news {
  margin-bottom: 100px;
}
.main--news .news__list {
  width: 960px;
  max-width: 90%;
  margin: 0 auto;
}
@media (max-width: 1024px) {
  .main--news .news__list {
    max-width: 100%;
  }
}
.main--news .news__item:nth-of-type(n+7) {
  animation: fade 1s ease-in-out;
}
.main--news .news__item .img-wrap {
  padding-bottom: 75%;
}
.main--news .news__item .txt-wrap {
  padding: 21px 6%;
}
@media (max-width: 1024px) {
  .main--news .news__item:nth-of-type(n+3) {
    margin-top: 22px;
  }
  .main--news .news__item .img-wrap {
    padding-bottom: 100%;
  }
}
.main--news .news .btn-row {
  text-align: center;
  margin-top: 40px;
}

.main--news-detail {
  margin-top: 23px;
}
@media (max-width: 1024px) {
  .main--news-detail {
    margin-top: 21px;
  }
}
.main--news-detail .under-ttl {
  margin-bottom: 41px;
}
@media (max-width: 1024px) {
  .main--news-detail .under-ttl {
    margin-bottom: 44px;
  }
}
.main--news-detail .detail {
  width: 720px;
  max-width: 90%;
  margin: 0 auto 106px;
}
@media (max-width: 599px) {
  .main--news-detail .detail {
    width: 315px;
    margin-bottom: 89px;
  }
}
.main--news-detail .detail__info {
  display: flex;
  margin-bottom: 10px;
}
.main--news-detail .detail__info .date {
  line-height: 22px;
  font-family: "Lora", "Noto Serif JP", serif;
  font-size: 1.6rem;
  margin: 0 10px 0 0;
}
.main--news-detail .detail__info .cat-row {
  margin: 0;
}
@media (max-width: 1024px) {
  .main--news-detail .detail__info {
    display: block;
  }
  .main--news-detail .detail__info .date {
    font-size: 1.4rem;
    line-height: 1.5rem;
    margin-bottom: 10px;
  }
  .main--news-detail .detail__info .cat-row {
    margin: 0 -5px;
  }
}
.main--news-detail .detail__ttl {
  margin-bottom: 27px;
}
@media (max-width: 1024px) {
  .main--news-detail .detail__ttl {
    margin-bottom: 23px;
  }
}
.main--news-detail .detail__img-wrap {
  text-align: center;
  margin-bottom: 24px;
}
@media (max-width: 1024px) {
  .main--news-detail .detail__img-wrap {
    margin-bottom: 19px;
  }
}
.main--news-detail .detail__content {
  font-size: 1.4rem;
  line-height: 2;
  letter-spacing: 0.1em;
}
.main--news-detail .detail__content p + p {
  margin-top: 2rem;
}
.main--news-detail .detail__btn-row {
  margin-top: 105px;
  text-align: center;
  position: relative;
  padding: 0 85px;
}
@media (max-width: 1024px) {
  .main--news-detail .detail__btn-row {
    margin-top: 85px;
  }
}
.main--news-detail .detail__btn-row .btn {
  color: #171717;
  font-family: "Lora", "Noto Serif JP", serif;
  font-weight: 500;
}
.main--news-detail .detail__btn-row .btn--top {
  font-size: 1.6rem;
  letter-spacing: 0.1em;
  text-decoration: underline;
}
.main--news-detail .detail__btn-row .btn--top:hover {
  text-decoration: none;
}
@media (max-width: 1024px) {
  .main--news-detail .detail__btn-row .btn--top {
    font-size: 1.4rem;
  }
}
.main--news-detail .detail__btn-row .btn--prev, .main--news-detail .detail__btn-row .btn--next {
  font-size: 1.3rem;
  letter-spacing: 0.025em;
  position: absolute;
}
.main--news-detail .detail__btn-row .btn--prev:before, .main--news-detail .detail__btn-row .btn--prev:after, .main--news-detail .detail__btn-row .btn--next:before, .main--news-detail .detail__btn-row .btn--next:after {
  content: "";
  display: block;
  position: absolute;
  transition: 0.3s ease;
  top: 50%;
  transform: translateY(-50%);
}
.main--news-detail .detail__btn-row .btn--prev:before, .main--news-detail .detail__btn-row .btn--next:before {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: solid 1px #171717;
}
.main--news-detail .detail__btn-row .btn--prev:after, .main--news-detail .detail__btn-row .btn--next:after {
  width: 10px;
  height: 10px;
  border-top: solid 2px #171717;
  border-right: solid 2px #171717;
}
.main--news-detail .detail__btn-row .btn--prev:hover:before, .main--news-detail .detail__btn-row .btn--next:hover:before {
  background: #171717;
}
.main--news-detail .detail__btn-row .btn--prev:hover:after, .main--news-detail .detail__btn-row .btn--next:hover:after {
  border-color: #fff;
}
.main--news-detail .detail__btn-row .btn--prev {
  left: 0;
  padding-left: 46px;
}
.main--news-detail .detail__btn-row .btn--prev:before {
  left: 0;
}
.main--news-detail .detail__btn-row .btn--prev:after {
  transform: translateY(-50%) rotate(-135deg);
  left: 14px;
}
.main--news-detail .detail__btn-row .btn--next {
  right: 0;
  padding-right: 46px;
}
.main--news-detail .detail__btn-row .btn--next:before {
  right: 0;
}
.main--news-detail .detail__btn-row .btn--next:after {
  transform: translateY(-50%) rotate(45deg);
  right: 14px;
}

/*　NEWS　ここまで　*/
/*　ARTIST / TALENT　ここから　*/
.main--a_t {
  margin-top: 23px;
  /*　ARTIST詳細　ここから　*/
  /*　ARTIST詳細　ここまで　*/
  /*　TALENT詳細　ここから　*/
  /*　TALENT詳細　ここまで　*/
}
@media (max-width: 1024px) {
  .main--a_t {
    margin-top: 21px;
  }
}
.main--a_t .under-ttl {
  margin-bottom: 43px;
}
.main--a_t .tab-area {
  width: 960px;
  max-width: 90%;
  margin: 0 auto 88px;
}
.main--a_t .tab-btns {
  display: flex;
  justify-content: space-between;
  margin-bottom: 55px;
}
@media (max-width: 1024px) {
  .main--a_t .tab-area {
    margin-bottom: 69px;
  }
  .main--a_t .tab-btns {
    margin-bottom: 35px;
  }
}
@media (max-width: 599px) {
  .main--a_t .tab-area {
    width: 315px;
  }
}
.main--a_t .tab-btn {
  width: 49%;
  padding: 13px 0;
  text-align: center;
  position: relative;
  border: solid 1px #171717;
  background: #171717;
  cursor: pointer;
  color: #fff;
  transition: 0.3s ease;
}
@media (max-width: 1024px) {
  .main--a_t .tab-btn {
    padding: 9px 0;
  }
}
.main--a_t .tab-btn .txt {
  font-family: "Lora", "Noto Serif JP", serif;
  font-size: 2.1rem;
  font-weight: 700;
  letter-spacing: 0.1em;
  position: relative;
  padding-right: 30px;
}
.main--a_t .tab-btn .txt:before, .main--a_t .tab-btn .txt:after {
  display: block;
  content: "";
  position: absolute;
}
.main--a_t .tab-btn .txt:before {
  width: 22px;
  height: 22px;
  background: #fff;
  border-radius: 50%;
  right: 0;
}
.main--a_t .tab-btn .txt:after {
  width: 5px;
  height: 5px;
  border-right: solid 2px #171717;
  border-top: solid 2px #171717;
  transform: rotate(45deg);
  right: 9px;
  top: 10px;
  transition: 0.3s ease;
}
@media (max-width: 1024px) {
  .main--a_t .tab-btn .txt {
    font-size: 1.6rem;
    padding-right: 24px;
  }
  .main--a_t .tab-btn .txt:before {
    width: 18px;
    height: 18px;
  }
  .main--a_t .tab-btn .txt:after {
    width: 4px;
    height: 4px;
    right: 7px;
    top: 8px;
  }
}
.main--a_t .tab-btn.select {
  background: #F4F4F4;
  color: #171717;
}
.main--a_t .tab-btn.select:before, .main--a_t .tab-btn.select:after {
  content: "";
  display: block;
  position: absolute;
  left: 50%;
  top: 100%;
  transform: translateX(-50%);
}
.main--a_t .tab-btn.select:before {
  border-top: solid 13px #171717;
  border-left: solid 10px transparent;
  border-right: solid 10px transparent;
}
.main--a_t .tab-btn.select:after {
  border-top: solid 12px #F4F4F4;
  border-left: solid 9px transparent;
  border-right: solid 9px transparent;
}
.main--a_t .tab-btn.select .txt:after {
  transform: rotate(135deg);
  right: 7px;
}
@media (max-width: 1024px) {
  .main--a_t .tab-btn.select .txt:after {
    right: 6px;
  }
}
.main--a_t .tab-btn:hover {
  background: #F4F4F4;
  color: #171717;
}
.main--a_t .tab {
  display: none;
}
.main--a_t .tab.show {
  display: block;
}
.main--a_t .talent-list {
  width: 720px;
  max-width: 100%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
}
@media (max-width: 1024px) {
  .main--a_t .talent-list {
    justify-content: space-between;
  }
}
.main--a_t .talent-list__link {
  display: block;
}
.main--a_t .talent-list__link:hover .name {
  text-decoration: underline;
}
@media (min-width: 1025px) {
  .main--a_t .talent-list__item {
    width: 28%;
    margin-right: 8%;
  }
  .main--a_t .talent-list__item:nth-of-type(3n) {
    margin-right: 0;
  }
  .main--a_t .talent-list__item:nth-of-type(n+4) {
    margin-top: 34px;
  }
}
@media (max-width: 1024px) {
  .main--a_t .talent-list__item {
    width: 48%;
  }
  .main--a_t .talent-list__item:nth-of-type(n+3) {
    margin-top: 30px;
  }
}
.main--a_t .talent-list__item .name {
  font-size: 1.6rem;
  font-weight: bold;
  letter-spacing: 0.1em;
  margin-bottom: 13px;
}
.main--a_t .talent-list__item .img-wrap {
  text-align: center;
  margin-bottom: 10px;
}
.main--a_t .talent-list__item .cat-row {
  margin: 0 -2px 6px;
  text-align: right;
}
.main--a_t .talent-list__item .cat {
  font-family: "Noto Sans JP", sans-serif;
  text-align: center;
  letter-spacing: 0.1em;
  color: #fff;
  background: #171717;
  border-radius: 100px;
  padding: 3px 5px;
  display: inline-block;
  margin: 0 2px 2px;
}
.main--a_t .talent-list__item .cat__txt {
  color: #fff;
  display: block;
  font-size: 1rem;
  transform: scale(0.9);
  transform-origin: left center;
  width: 111.1111111111%;
}
@media (max-width: 1024px) {
  .main--a_t .talent-list__item .name {
    font-size: 1.4rem;
    margin-bottom: 10px;
  }
  .main--a_t .talent-list__item .img-wrap {
    margin-bottom: 8px;
  }
}
.main--a_t .artist-list {
  max-width: 100%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
}
@media (max-width: 1024px) {
  .main--a_t .artist-list {
    padding: 8px 3px 0;
  }
}
.main--a_t .artist-list__link {
  display: block;
}
.main--a_t .artist-list__link:hover .img-wrap .on {
  opacity: 1;
}
@media (min-width: 1025px) {
  .main--a_t .artist-list__item:nth-of-type(-n+2) {
    width: 49%;
    margin-right: 2%;
  }
  .main--a_t .artist-list__item:nth-of-type(2) {
    margin-right: 0;
  }
  .main--a_t .artist-list__item:nth-of-type(n+3) {
    width: 32.3%;
    margin-right: 1.55%;
    margin-top: 56px;
  }
  .main--a_t .artist-list__item:nth-of-type(3n+5) {
    margin-right: 0;
  }
}
@media (max-width: 1024px) {
  .main--a_t .artist-list__item {
    width: 100%;
  }
  .main--a_t .artist-list__item:nth-of-type(n+2) {
    margin-top: 45px;
  }
}
.main--a_t .artist-list__item .name {
  font-size: 1.6rem;
  font-weight: bold;
  letter-spacing: 0.1em;
  margin-bottom: 15px;
  border-left: solid 4px #171717;
  padding: 1px 10px;
}
.main--a_t .artist-list__item .img-wrap {
  text-align: center;
  border: solid 1px #8A8A8A;
  position: relative;
}
.main--a_t .artist-list__item .img-wrap .on {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  opacity: 0;
}
.main--a_t .artist-list__item .link-row {
  margin-top: 14px;
  letter-spacing: 0.1em;
  text-align: right;
}
.main--a_t .artist {
  width: 720px;
  max-width: 90%;
  margin: 0 auto 90px;
}
@media (max-width: 599px) {
  .main--a_t .artist {
    width: 315px;
  }
}
.main--a_t .artist__img-wrap {
  text-align: center;
  margin-bottom: 15px;
}
.main--a_t .artist__txt-wrap {
  font-size: 1.6rem;
  letter-spacing: 0.1em;
  line-height: 1.75;
  margin-bottom: 27px;
}
@media (max-width: 1024px) {
  .main--a_t .artist__txt-wrap {
    margin-bottom: 23px;
  }
}
.main--a_t .artist__link-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 43px;
}
.main--a_t .artist__link-row .link-arrow {
  letter-spacing: 0.1em;
}
.main--a_t .artist__link-row .sns__link:nth-of-type(n+2) {
  margin-left: 10px;
}
.main--a_t .artist__link-row .sns__link img {
  max-height: 30px;
}
@media (max-width: 1024px) {
  .main--a_t .artist__link-row {
    display: block;
  }
  .main--a_t .artist__link-row .link-arrow {
    margin-bottom: 13px;
  }
}
.main--a_t .talent {
  width: 960px;
  max-width: 90%;
  margin: 0 auto 100px;
  display: flex;
  justify-content: space-between;
}
@media (max-width: 1024px) {
  .main--a_t .talent {
    display: block;
  }
}
@media (max-width: 599px) {
  .main--a_t .talent {
    width: 315px;
  }
}
.main--a_t .talent__left {
  width: 44%;
}
@media (max-width: 1024px) {
  .main--a_t .talent__left {
    width: 100%;
    margin-bottom: 9px;
  }
}
.main--a_t .talent__slider .slick-prev,
.main--a_t .talent__slider .slick-next {
  width: 32px;
  height: 32px;
  border: solid 1px #171717;
  border-radius: 50%;
  top: calc(50% - 9px);
  z-index: 99;
}
.main--a_t .talent__slider .slick-prev:before,
.main--a_t .talent__slider .slick-next:before {
  content: "";
  display: block;
  position: absolute;
  width: 10px;
  height: 10px;
  border-top: solid 1px #171717;
  border-right: solid 1px #171717;
  opacity: 1;
  top: 50%;
}
.main--a_t .talent__slider .slick-prev {
  left: 0;
}
.main--a_t .talent__slider .slick-prev:before {
  transform: translateY(-50%) rotate(-135deg);
  left: 12px;
}
.main--a_t .talent__slider .slick-next {
  right: 0;
}
.main--a_t .talent__slider .slick-next:before {
  transform: translateY(-50%) rotate(45deg);
  right: 12px;
}
.main--a_t .talent__slide {
  padding: 0 40px 15px;
}
.main--a_t .talent__slide img {
  margin: 0 auto;
  box-shadow: 8px 3px 12px rgba(0, 0, 0, 0.16);
}
.main--a_t .talent__right {
  width: 52%;
}
@media (max-width: 1024px) {
  .main--a_t .talent__right {
    width: 100%;
  }
}
.main--a_t .talent__ttl {
  margin-bottom: 13px;
}
.main--a_t .talent__ttl .kana {
  font-size: 1.6rem;
  vertical-align: baseline;
  letter-spacing: 0.1em;
}
@media (max-width: 1024px) {
  .main--a_t .talent__ttl .kana {
    font-size: 1.4rem;
  }
}
.main--a_t .talent__txt-wrap {
  line-height: 1.7142857143;
  letter-spacing: 0.1em;
  margin-bottom: 24px;
}
.main--a_t .talent__dt {
  margin-bottom: 5px;
}
.main--a_t .talent__dd + .artist__dt {
  margin-top: 25px;
}
.main--a_t .talent .txt {
  line-height: 1.7142857143;
  letter-spacing: 0.1em;
}
.main--a_t .talent .sns {
  margin-left: -4.5%;
}
.main--a_t .talent .sns__link {
  display: inline-block;
  margin: 0 0 5px 4.5%;
}
.main--a_t .talent .link-arrow {
  font-size: 1.6rem;
  letter-spacing: 0.1em;
}
.main--a_t .talent .link-arrow .arrow {
  top: 6px;
}
@media (max-width: 1024px) {
  .main--a_t .talent__txt-wrap {
    margin-bottom: 20px;
  }
  .main--a_t .talent__dd + .artist__dt {
    margin-top: 19px;
  }
  .main--a_t .talent .sns {
    margin-left: -5%;
  }
  .main--a_t .talent .sns__link {
    margin: 0 0 5px 5%;
  }
  .main--a_t .talent .sns__ic {
    height: 32px;
  }
}

/*　ARTIST / TALENT　ここまで　*/
/*　ABOUT　ここから　*/
.main--about {
  margin-top: 23px;
}
@media (max-width: 1024px) {
  .main--about {
    margin-top: 21px;
  }
}
.main--about .under-ttl {
  margin-bottom: 14px;
}
@media (max-width: 1024px) {
  .main--about .under-ttl {
    margin-bottom: 23px;
  }
}
.main--about .about {
  width: 760px;
  max-width: 90%;
  margin: 0 auto 100px;
}
@media (max-width: 1024px) {
  .main--about .about {
    margin-bottom: 80px;
  }
}
@media (max-width: 599px) {
  .main--about .about {
    width: 315px;
  }
}

/*　ABOUT　ここまで　*/
/*　CONTACT　ここから　*/
.form-wrap .confirm-txt {
  display: none;
}
.form-wrap .btn-row {
  margin-top: 40px;
  text-align: center;
}
.form-wrap .btn-row .btn-wrap {
  max-width: 100%;
  display: inline-block;
}
.form-wrap .btn-row .btn {
  display: block;
  width: 100%;
  font-size: 1.6rem;
  letter-spacing: 0.1em;
  font-family: "Lora", "Noto Serif JP", serif;
  font-weight: 500;
  color: #171717;
  background: #F4F4F4;
  border: solid 1px #171717;
  border-radius: 100px;
  line-height: 1.375;
  padding: 23px 67px 23px 23px;
  position: relative;
  cursor: pointer;
  transition: 0.3s ease;
}
.form-wrap .btn-row .btn--back {
  background: #fff;
}
.form-wrap .btn-row .btn:hover {
  background: #171717;
  color: #fff;
}
@media (max-width: 1024px) {
  .form-wrap .btn-row .btn {
    padding: 18px 70px 18px 20px;
  }
  .form-wrap .btn-row .btn .arrow {
    width: 30px;
    height: 30px;
  }
  .form-wrap .btn-row .btn .arrow:before, .form-wrap .btn-row .btn .arrow:after {
    right: 8px;
  }
}

.main--contact {
  margin-top: 23px;
}
@media (max-width: 1024px) {
  .main--contact {
    margin-top: 21px;
  }
}
.main--contact .under-ttl {
  margin-bottom: 42px;
}
@media (max-width: 1024px) {
  .main--contact .under-ttl {
    margin-bottom: 45px;
  }
}
.main--contact .form-wrap {
  width: 720px;
  max-width: 90%;
  margin: 0 auto 100px;
}
@media (max-width: 1024px) {
  .main--contact .form-wrap {
    margin-bottom: 80px;
  }
}
@media (max-width: 599px) {
  .main--contact .form-wrap {
    width: 315px;
  }
}
.main--contact .form-wrap input,
.main--contact .form-wrap textarea,
.main--contact .form-wrap select {
  font-family: "Noto Serif JP", serif;
  border: solid 1px #D8D8D8;
  box-sizing: border-box;
  padding-left: 1rem;
  outline: none;
  border-radius: 8px;
}
.main--contact .form-wrap input::placeholder,
.main--contact .form-wrap textarea::placeholder,
.main--contact .form-wrap select::placeholder {
  color: #C8C8C8;
  font-size: 1.4rem;
  font-weight: 300;
}
.main--contact .form-wrap input[type=text],
.main--contact .form-wrap input[type=email] {
  height: 50px;
  width: 100%;
}
.main--contact .form-wrap textarea {
  width: 100%;
  height: 230px;
  padding: 1rem;
  resize: none;
}
.main--contact .form-wrap .select-wrap {
  position: relative;
  width: 47%;
}
.main--contact .form-wrap .select-wrap:before {
  content: "";
  display: block;
  position: absolute;
  right: 14px;
  top: calc(50% - 6px);
  width: 6px;
  height: 6px;
  transform: rotate(45deg);
  border-bottom: solid 2px #171717;
  border-right: solid 2px #171717;
  pointer-events: none;
}
.main--contact .form-wrap .select-wrap select {
  width: 100%;
  height: 50px;
  cursor: pointer;
  -webkit-appearance: none; /* ベンダープレフィックス(Google Chrome、Safari用) */
  -moz-appearance: none; /* ベンダープレフィックス(Firefox用) */
  appearance: none; /* 標準のスタイルを無効にする */
  font-size: 1.5rem;
  font-weight: normal;
}
.main--contact .form-wrap .select-wrap ::-ms-expand {
  display: none;
}
@media (max-width: 1024px) {
  .main--contact .form-wrap .select-wrap {
    width: 100%;
  }
}
.main--contact .form-wrap input[type=submit],
.main--contact .form-wrap input[type=button] {
  border-radius: 0;
  -webkit-box-sizing: content-box;
  -webkit-appearance: button;
  appearance: button;
  border: none;
  box-sizing: border-box;
}
.main--contact .form-wrap input[type=submit]::-webkit-search-decoration,
.main--contact .form-wrap input[type=button]::-webkit-search-decoration {
  display: none;
}
.main--contact .form-wrap input[type=submit]::focus,
.main--contact .form-wrap input[type=button]::focus {
  outline-offset: -2px;
}
.main--contact .form-wrap .dl + .dl {
  margin-top: 41px;
}
.main--contact .form-wrap .dl .dt {
  padding-bottom: 12px;
}
.main--contact .form-wrap .dl .dt__txt {
  font-size: 1.6rem;
  font-weight: bold;
  vertical-align: middle;
}
.main--contact .form-wrap .dl .dt .required {
  display: inline-block;
  font-size: 1.2rem;
  font-family: "Noto Sans JP", sans-serif;
  text-align: center;
  padding: 4px 10px;
  margin-left: 10px;
  border: solid 1px #171717;
  vertical-align: middle;
}
.main--contact .form-wrap .dl .dd {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.main--contact .form-wrap .dl .input-wrap {
  width: 100%;
}
.main--contact .form-wrap .dl .input-wrap--half {
  width: 48%;
}
.main--contact .form-wrap .dl .input-wrap--row2 + .input-wrap--row2 {
  margin-top: 10px;
}
.main--contact .form-wrap .btn-row {
  margin-top: 40px;
}

.main--confirm {
  margin-top: 23px;
}
@media (max-width: 1024px) {
  .main--confirm {
    margin-top: 21px;
  }
}
.main--confirm .under-ttl {
  margin-bottom: 44px;
}
.main--confirm .confirm {
  width: 720px;
  max-width: 90%;
  margin: 0 auto 100px;
}
@media (max-width: 1024px) {
  .main--confirm .confirm {
    margin-bottom: 80px;
  }
}
@media (max-width: 599px) {
  .main--confirm .confirm {
    width: 315px;
  }
}
.main--confirm .confirm__ttl {
  margin-bottom: 31px;
}
@media (max-width: 1024px) {
  .main--confirm .confirm__ttl {
    margin-bottom: 21px;
  }
}
.main--confirm .form-wrap .dl {
  display: flex;
  justify-content: space-between;
  font-size: 1.6rem;
  line-height: 1.75;
}
.main--confirm .form-wrap .dl + .dl {
  margin-top: 24px;
}
.main--confirm .form-wrap .dl .dt {
  width: 26%;
}
.main--confirm .form-wrap .dl .dt__txt {
  font-weight: bold;
}
.main--confirm .form-wrap .dl .dt .required {
  display: none;
}
.main--confirm .form-wrap .dl .dd {
  width: 73%;
}
.main--confirm .form-wrap .dl .input-wrap--row2 + .input-wrap--row2 {
  display: none;
}
@media (max-width: 1024px) {
  .main--confirm .form-wrap .dl {
    display: block;
  }
  .main--confirm .form-wrap .dl .dt {
    width: 100%;
  }
  .main--confirm .form-wrap .dl .dd {
    width: 100%;
    font-size: 1.4rem;
    line-height: 2;
  }
}
.main--confirm .form-wrap .confirm-txt {
  display: block;
  text-align: center;
  font-size: 1.6rem;
  line-height: 1.75;
  margin: 57px 0;
}
@media (max-width: 1024px) {
  .main--confirm .form-wrap .confirm-txt {
    margin: 36px 0;
  }
}
.main--confirm .form-wrap .btn-row {
  margin-top: 0;
}
.main--confirm .form-wrap .btn-row .btn-wrap {
  width: 200px;
  margin: 0 20px;
}
.main--confirm .form-wrap .btn-row .btn-wrap--confirm {
  width: 0;
  margin: 0;
}
.main--confirm .form-wrap .btn-row .btn {
  padding-left: 5px;
  padding-right: 5px;
}
@media (max-width: 1024px) {
  .main--confirm .form-wrap .btn-row {
    display: flex;
    justify-content: center;
  }
  .main--confirm .form-wrap .btn-row .btn-wrap {
    margin: 0;
    max-width: 47%;
  }
  .main--confirm .form-wrap .btn-row .btn-wrap--back {
    margin-right: 6%;
  }
}

.main--completion {
  margin-top: 23px;
}
@media (max-width: 1024px) {
  .main--completion {
    margin-top: 21px;
  }
}
.main--completion .under-ttl {
  margin-bottom: 44px;
}
.main--completion .completion {
  width: 720px;
  max-width: 90%;
  margin: 0 auto 100px;
}
@media (max-width: 1024px) {
  .main--completion .completion {
    margin-bottom: 80px;
  }
}
@media (max-width: 599px) {
  .main--completion .completion {
    width: 315px;
  }
}
.main--completion .completion__ttl {
  margin-bottom: 37px;
}
.main--completion .completion__txt-wrap {
  text-align: center;
  margin-bottom: 37px;
}
.main--completion .completion__txt-wrap .txt {
  font-size: 1.6rem;
  line-height: 1.75;
}
.main--completion .completion__txt-wrap .txt + .txt {
  margin-top: 28px;
}
.main--completion .completion__btn-row {
  text-align: center;
}
.main--completion .completion__btn-row .btn-arrow {
  width: 200px;
}

/*　CONTACT　ここまで　*/
/*　AUDITION　ここから　*/
.main--audition {
  margin-top: 23px;
}
@media (max-width: 1024px) {
  .main--audition {
    margin-top: 21px;
  }
}
.main--audition .under-ttl {
  margin-bottom: 43px;
}
.main--audition .audition {
  width: 960px;
  max-width: 90%;
  margin: 0 auto 100px;
}
@media (max-width: 1024px) {
  .main--audition .audition {
    margin-bottom: 80px;
  }
}
@media (max-width: 599px) {
  .main--audition .audition {
    width: 315px;
  }
}
.main--audition .audition .ttl-border {
  margin-bottom: 27px;
}
@media (max-width: 1024px) {
  .main--audition .audition .ttl-border {
    margin-bottom: 24px;
  }
}
.main--audition .audition .btn-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 50px;
}
.main--audition .audition .btn-row .btn-arrow {
  margin: 0 10px 20px;
  width: 320px;
  padding-left: 30px;
}
@media (max-width: 1024px) {
  .main--audition .audition .btn-row {
    flex-direction: column;
    align-items: center;
    margin-top: 37px;
  }
  .main--audition .audition .btn-row .btn-arrow {
    width: 295px;
    margin: 0;
  }
  .main--audition .audition .btn-row .btn-arrow + .btn-arrow {
    margin-top: 20px;
  }
}
.main--audition .audition .sec + .sec {
  margin-top: 100px;
}
@media (max-width: 1024px) {
  .main--audition .audition .sec + .sec {
    margin-top: 78px;
  }
}
.main--audition .audition__intro {
  text-align: center;
  margin-bottom: 95px;
}
.main--audition .audition__intro .logo-wrap {
  margin-bottom: 40px;
}
.main--audition .audition__intro .img-wrap {
  margin-bottom: 50px;
}
.main--audition .audition__intro .lead {
  font-weight: 900;
  font-size: 4.8rem;
  letter-spacing: 0.1em;
  margin-bottom: 47px;
}
.main--audition .audition__intro .txt {
  font-size: 1.6rem;
  letter-spacing: 0.1em;
  line-height: 2.25;
}
@media (max-width: 1024px) {
  .main--audition .audition__intro {
    margin-bottom: 77px;
  }
  .main--audition .audition__intro .logo-wrap img {
    max-width: 312px;
  }
  .main--audition .audition__intro .img-wrap {
    margin-bottom: 45px;
  }
  .main--audition .audition__intro .lead {
    font-size: 2.4rem;
    margin-bottom: 42px;
  }
  .main--audition .audition__intro .txt {
    font-size: 1.4rem;
    line-height: 2;
  }
}
.main--audition .audition .method__txt-wrap + .ttl-bg {
  margin-top: 40px;
}
.main--audition .audition .method__txt-wrap .btn-row {
  margin: 36px 0 59px;
}
@media (max-width: 1024px) {
  .main--audition .audition .method__txt-wrap .btn-row {
    margin: 32px 0 40px;
  }
}
.main--audition .audition .method__row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 14px;
}
@media (max-width: 1024px) {
  .main--audition .audition .method__row {
    flex-direction: column-reverse;
  }
  .main--audition .audition .method__row .qr-wrap {
    text-align: center;
    margin-bottom: 16px;
  }
}
.main--audition .audition .method .txt {
  letter-spacing: 0.1em;
  line-height: 1.7142857143;
}
.main--audition .audition .method .txt + .txt {
  margin-top: 24px;
}
.main--audition .audition .method .txt + .list-indent {
  margin-top: 12px;
}
.main--audition .audition .method .list-indent {
  line-height: 1.7142857143;
}
@media (max-width: 1024px) {
  .main--audition .audition .method .list-indent {
    font-size: 1.4rem;
  }
}
.main--audition .audition .method .link-row {
  letter-spacing: 0.1em;
  margin-top: 18px;
}
.main--audition .audition .method .link-row + .link-row {
  margin-top: 15px;
}
.main--audition .audition .method .link-row .ttl {
  letter-spacing: 0;
  font-size: 1.6rem;
  vertical-align: baseline;
  margin-right: 8px;
}
.main--audition .audition .method .boxed .ttl {
  font-family: "Lora", "Noto Serif JP", serif;
  font-size: 1.6rem;
  letter-spacing: 0.1em;
  font-weight: bold;
  line-height: 1.75;
  margin-bottom: 5px;
}
@media (min-width: 1025px) {
  .main--audition .audition .message__box {
    padding-right: 2%;
  }
}
.main--audition .audition .message__box + .message__box {
  margin-top: 30px;
}
.main--audition .audition .message__box + .ttl-bg {
  margin-top: 40px;
}
.main--audition .audition .message .info-row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 12px;
}
.main--audition .audition .message .info-row .name {
  font-size: 1.8rem;
  font-weight: bold;
  letter-spacing: 0.1em;
  line-height: 1.5555555556;
  margin-bottom: 2px;
}
.main--audition .audition .message .info-row .cat-row {
  margin-left: 5px;
}
.main--audition .audition .message .info-row .cat {
  font-family: "Noto Sans JP", sans-serif;
  text-align: center;
  letter-spacing: 0.1em;
  color: #fff;
  background: #171717;
  border-radius: 100px;
  padding: 3px 5px 4px;
  display: inline-block;
  margin: 0 2px 2px;
}
.main--audition .audition .message .info-row .cat__txt {
  display: block;
  font-size: 1rem;
  transform: scale(0.9);
  transform-origin: left center;
  width: 111.1111111111%;
}
.main--audition .audition .message__row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 38px;
}
.main--audition .audition .message__row .txt-wrap {
  width: 63%;
}
.main--audition .audition .message__row .img-wrap {
  width: 36%;
  text-align: right;
}
@media (max-width: 1024px) {
  .main--audition .audition .message__row {
    flex-direction: column-reverse;
  }
  .main--audition .audition .message__row .txt-wrap {
    width: 100%;
  }
  .main--audition .audition .message__row .img-wrap {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
  }
}
.main--audition .audition .message .txt {
  letter-spacing: 0.1em;
  line-height: 1.7142857143;
}
.main--audition .audition .message .txt + .txt {
  margin-top: 24px;
}
.main--audition .audition .message .ttl-left {
  margin-bottom: 5px;
}

/*　AUDITION　ここまで　*/
/*　RECRUIT　ここから　*/
.main--recruit {
  margin-top: 23px;
}
@media (max-width: 1024px) {
  .main--recruit {
    margin-top: 21px;
  }
}
.main--recruit .under-ttl {
  margin-bottom: 43px;
}
.main--recruit .recruit {
  width: 960px;
  max-width: 90%;
  margin: 0 auto 100px;
}
@media (max-width: 1024px) {
  .main--recruit .recruit {
    margin-bottom: 80px;
  }
}
@media (max-width: 599px) {
  .main--recruit .recruit {
    width: 315px;
  }
}
.main--recruit .recruit .ttl-border {
  margin-bottom: 27px;
}
@media (max-width: 1024px) {
  .main--recruit .recruit .ttl-border {
    margin-bottom: 24px;
  }
}
.main--recruit .recruit .btn-row {
  text-align: center;
  margin-top: 40px;
}
.main--recruit .recruit .btn-row .btn-arrow {
  width: 320px;
  padding-left: 73px;
}
@media (max-width: 1024px) {
  .main--recruit .recruit .btn-row .btn-arrow {
    width: 295px;
    text-align: center;
    padding-left: 45px;
    padding-right: 45px;
  }
}
.main--recruit .job__inner {
  width: 760px;
  max-width: 100%;
  margin: 0 auto;
}
.main--recruit .job__img-wrap {
  text-align: center;
}
@media (max-width: 1024px) {
  .main--recruit .job__img-wrap {
    margin-bottom: 5px;
  }
}
.main--recruit .job .ttl-bg {
  margin-bottom: 0;
}
.main--recruit .job__dl + .ttl-bg {
  margin-top: 40px;
}
.main--recruit .job .tag-list {
  margin: 30px -2px 33px;
}
.main--recruit .job .tag-list__item {
  font-family: "Noto Sans JP", sans-serif;
  font-size: 1.1rem;
  font-weight: 500;
  text-align: center;
  letter-spacing: 0.1em;
  color: #fff;
  background: #171717;
  border-radius: 100px;
  padding: 5px 8px;
  display: inline-block;
  margin: 0 2px 8px;
}
@media (max-width: 1024px) {
  .main--recruit .job .tag-list {
    margin: 25px -2px 33px;
  }
  .main--recruit .job .tag-list__item {
    font-size: 1rem;
    margin: 0 2px 7px;
  }
}
.main--recruit .job .map {
  margin-top: 18px;
  padding-bottom: 55.5%;
}
@media (max-width: 1024px) {
  .main--recruit .job .map {
    margin-top: 4px;
  }
}
.main--recruit .job .txt + .txt {
  margin-top: 28px;
}
.main--recruit .job .table-decision__td {
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.1em;
  vertical-align: top;
}
.main--recruit .job .table-decision__td:first-of-type {
  text-align: right;
}
.main--recruit .job .table-decision__td:nth-of-type(2) {
  padding: 0 15px;
}
.main--recruit .job .table-decision .gy {
  color: #D8D8D8;
}
@media (max-width: 1024px) {
  .main--recruit .job .table-decision__td {
    font-size: 1.4rem;
    line-height: 2;
  }
}

/*　RECRUIT　ここまで　*/
/*　PRIVACY POLICY　ここから　*/
.main--pp {
  margin-top: 23px;
}
@media (max-width: 1024px) {
  .main--pp {
    margin-top: 21px;
  }
}
.main--pp .under-ttl {
  margin-bottom: 73px;
}
@media (max-width: 1024px) {
  .main--pp .under-ttl {
    margin-bottom: 37px;
  }
}
.main--pp .pp {
  width: 960px;
  max-width: 90%;
  margin: 0 auto 100px;
}
@media (max-width: 1024px) {
  .main--pp .pp {
    margin-bottom: 80px;
  }
}
@media (max-width: 599px) {
  .main--pp .pp {
    width: 315px;
  }
}
.main--pp .pp__lead {
  letter-spacing: 0.1em;
  line-height: 1.7142857143;
  margin-bottom: 60px;
}
.main--pp .pp__dl {
  margin-bottom: 40px;
}
@media (max-width: 1024px) {
  .main--pp .pp .list-indent {
    padding-left: 0;
  }
}
.main--pp .pp__dt {
  margin-bottom: 10px;
}
.main--pp .pp__dd {
  letter-spacing: 0.1em;
  line-height: 1.7142857143;
  margin-bottom: 40px;
}
.main--pp .pp__date {
  text-align: right;
}

/*　PRIVACY POLICY　ここまで　*/