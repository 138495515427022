@use "module";


/*　トップ　ここから　*/

.main--home{
	.mv{
		margin-bottom: 105px;
		&__slide{
			img{
				width: 100%;
			}
		}
		@include module.tab{
			margin-bottom: 81px;
		}
	}
	.slick-dots{
		bottom:18px;
		li{
			width: 40px;
			height: 3px;
			button{
				width: 100%;
				height: 100%;
				padding: 0;
				background: #D8D8D8;
				&:before{
					display: none;
				}
			}
			&.slick-active{
				button{
					background: #707070;
				}
			}
		}
		@include module.tab{
			bottom:8px;
			li{
				width: 30px;
			}
		}
	}
	.news{
		width: 1266px;
		max-width: 90%;
		margin: 0 auto 100px;
		@include module.tab{
			max-width: calc(100% - 4px);
		}
		&__ttl{
			margin-bottom: 43px;
			text-align: center;
			.en{
				font-size: 3.8rem;
				font-family: module.$lora;
				font-weight: module.$bold;
			}
			.jp{
				display: block;
				margin-top: 7px;
				font-size: 1.2rem;
				font-family: module.$noto;
				letter-spacing: 0.05em;
			}
			@include module.tab{
				.en{
					font-size: 3rem;
				}
				.jp{
					font-size: 1.2rem;
				}
			}
		}
		.btn-row{
			text-align: center;
			margin-top: 58px;
			@include module.tab{
				margin-top: 37px;
			}
		}
	}
	.banner{
		margin-bottom: 100px;
		text-align: center;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		&__link{
			margin: 10px;
			display: flex;
			justify-content: center;
			align-items: center;
		}
		@include module.tab{
			padding: 0 5%;
			margin-bottom: 80px;
		}
	}
}

/*　トップ　ここまで　*/


/*　NEWS　ここから　*/


@keyframes fade{
	0%{
		opacity: 0;
	}
	100%{
		opacity: 1;
	}
}

.main--news{
	margin-top: 23px;
	@include module.tab{
		margin-top: 21px;
	}
	.under-ttl{
		margin-bottom: 73px;
		@include module.tab{
			margin-bottom: 37px;
		}
	}
	.news{
		margin-bottom: 100px;
		&__list{
		  width: 960px;
		  max-width: 90%;
		  margin: 0 auto;
		  @include module.tab{
		    max-width:100%;
		  }
		}
		&__item{
			&:nth-of-type(n+7){
				animation: fade 1s ease-in-out;
			}
			.img-wrap{
				padding-bottom: 75%;
			}
			.txt-wrap{
				padding: 21px 6%;
			}
			@include module.tab{
				&:nth-of-type(n+3){
					margin-top: 22px;
				}
				.img-wrap{
					padding-bottom: 100%;
				}
			}
		}
		.btn-row{
			text-align: center;
			margin-top: 40px;
		}
	}
}

.main--news-detail{
	margin-top: 23px;
	@include module.tab{
		margin-top: 21px;
	}
	.under-ttl{
		margin-bottom: 41px;
		@include module.tab{
			margin-bottom: 44px;
		}
	}
	.detail{
		width: 720px;
		max-width:90%;
		margin: 0 auto 106px;
	  @include module.sp{
	    width: 315px;
	    margin-bottom: 89px;
	  }
		&__info{
			display: flex;
			margin-bottom: 10px;
			.date{
				line-height: 22px;
				font-family: module.$lora;
				font-size: 1.6rem;
				margin: 0 10px 0 0;
			}
			.cat-row{
				margin: 0;
			}
			@include module.tab{
				display: block;
				.date{
					font-size: 1.4rem;
					line-height: 1.5rem;
					margin-bottom: 10px;
				}
				.cat-row{
					margin: 0 -5px;
				}
			}
		}
		&__ttl{
			margin-bottom: 27px;
			@include module.tab{
				margin-bottom: 23px;
			}
		}
		&__img-wrap{
			text-align: center;
			margin-bottom: 24px;
			@include module.tab{
				margin-bottom: 19px;
			}
		}
		&__content{
			font-size: 1.4rem;
			line-height: 2;
			letter-spacing: 0.1em;
			p+p{
				margin-top: 2rem;
			}
		}
		&__btn-row{
			margin-top: 105px;
			text-align: center;
			position: relative;
			padding: 0 85px;
			@include module.tab{
				margin-top: 85px;
			}
			.btn{
				color: module.$bl;
				font-family: module.$lora;
				font-weight: module.$medium;
				&--top{
					font-size: 1.6rem;
					letter-spacing: 0.1em;
					text-decoration:underline;
					&:hover{
						text-decoration:none;
					}
					@include module.tab{
						font-size: 1.4rem;
					}
				}
				&--prev,
				&--next{
					font-size: 1.3rem;
					letter-spacing: 0.025em;
	        position: absolute;
		      &:before,
		      &:after{
	          content:"";
	          display: block;
	          position: absolute;
	          transition: .3s ease;
	          top:50%;
	          transform: translateY(-50%);
		      }
          &:before{
          	width: 32px;
          	height: 32px;
          	border-radius:50%;
		        border: solid 1px module.$bl;
          }
	        &:after{
	          width: 10px;
	          height: 10px;
	          border-top: solid 2px module.$bl;
	          border-right: solid 2px module.$bl;
	        }
	        &:hover{
	          &:before{
	          	background: module.$bl;
	          }
	          &:after{
	            border-color:#fff;
	          }
	        }
				}
	      &--prev{
	      	left:0;
	      	padding-left: 46px;
	      	&:before{
	      		left:0;
	      	}
	        &:after{
	          transform: translateY(-50%) rotate(-135deg);
	          left: 14px;
	        }
	      }
	      &--next{
	      	right:0;
	      	padding-right: 46px;
	      	&:before{
	      		right:0;
	      	}
	        &:after{
	          transform: translateY(-50%) rotate(45deg);
	          right: 14px;
	        }
	      }
			}
		}
	}
}

/*　NEWS　ここまで　*/


/*　ARTIST / TALENT　ここから　*/

.main--a_t{
	margin-top: 23px;
	@include module.tab{
		margin-top: 21px;
	}
	.under-ttl{
		margin-bottom: 43px;
	}
	.tab-area{
		width: 960px;
		max-width:90%;
		margin: 0 auto 88px;
	}
	.tab-btns{
		display: flex;
		justify-content: space-between;
		margin-bottom: 55px;
	}
	@include module.tab{
		.tab-area{
			margin-bottom: 69px;
		}
		.tab-btns{
			margin-bottom: 35px;
		}
	}
	@include module.sp{
		.tab-area{
			width: 315px;
		}
	}
	.tab-btn{
		width: 49%;
		padding: 13px 0;
		text-align: center;
		position: relative;
		border: solid 1px module.$bl;
		background: module.$bl;
		cursor: pointer;
		color: #fff;
		transition:.3s ease;
		@include module.tab{
			padding: 9px 0;
		}
		.txt{
			font-family: module.$lora;
			font-size: 2.1rem;
			font-weight: module.$bold;
			letter-spacing: 0.1em;
			position: relative;
			padding-right: 30px;
      &:before,
      &:after{
        display: block;
        content:"";
        position: absolute;
      }
      &:before{
        width: 22px;
        height: 22px;
        background: #fff;
        border-radius: 50%;
        right:0;
      }
      &:after{
        width: 5px;
        height: 5px;
        border-right: solid 2px module.$bl;
        border-top: solid 2px module.$bl;
        transform: rotate(45deg);
        right:9px;
        top:10px;
        transition:.3s ease;
      }
      @include module.tab{
      	font-size: 1.6rem;
      	padding-right: 24px;
      	&:before{
      		width: 18px;
      		height: 18px;
      	}
      	&:after{
      		width: 4px;
      		height: 4px;
      		right:7px;
      		top: 8px;
      	}
      }
    }
    &.select{
    	background: #F4F4F4;
    	color: module.$bl;
    	&:before,
    	&:after{
    		content:"";
    		display: block;
    		position: absolute;
    		left:50%;
    		top:100%;
    		transform:translateX(-50%);
    	}
    	&:before{
    		border-top: solid 13px module.$bl;
    		border-left: solid 10px transparent;
    		border-right: solid 10px transparent;
    	}
    	&:after{
    		border-top: solid 12px #F4F4F4;
    		border-left: solid 9px transparent;
    		border-right: solid 9px transparent;
    	}
    	.txt{
    		&:after{
    			transform: rotate(135deg);
    			right:7px;
    		}
    		@include module.tab{
    			&:after{
    				right:6px;
    			}
    		}
    	}
    }
    &:hover{
    	background: #F4F4F4;
    	color: module.$bl;
    }
	}
	.tab{
		display: none;
		&.show{
			display: block;
		}
	}
	.talent-list{
		width: 720px;
		max-width: 100%;
		margin: 0 auto;
		display: flex;
		flex-wrap:wrap;
		@include module.tab{
			justify-content: space-between;
		}
		&__link{
			display: block;
			&:hover{
				.name{
					text-decoration:underline;
				}
			}
		}
		&__item{
	    @include module.pc{
	      width: 28%;
	      margin-right: 8%;
	      &:nth-of-type(3n){
	        margin-right: 0;
	      }
	      &:nth-of-type(n+4){
	        margin-top: 34px;
	      }
	    }
	    @include module.tab{
	      width: 48%;
	      &:nth-of-type(n+3){
	        margin-top: 30px;
	      }
	    }
	    .name{
	    	font-size: 1.6rem;
	    	font-weight: bold;
	    	letter-spacing: 0.1em;
	    	margin-bottom: 13px;
	    }
	    .img-wrap{
	    	text-align: center;
	    	margin-bottom: 10px;
	    }
      .cat-row{
        margin: 0 -2px 6px;
        text-align: right;
      }
      .cat{
      	font-family: module.$sans;
      	text-align: center;
        letter-spacing: 0.1em;
        color: #fff;
        background: module.$bl;
        border-radius:100px;
        padding: 3px 5px;
        display: inline-block;
        margin: 0 2px 2px;
        &__txt{
        	color: #fff;
        	@include module.fz9;
        }
      }
      @include module.tab{
      	.name{
      		font-size: 1.4rem;
      		margin-bottom: 10px;
      	}
      	.img-wrap{
      		margin-bottom: 8px;
      	}
      }
		}
	}
	.artist-list{
		max-width: 100%;
		margin: 0 auto;
		display: flex;
		flex-wrap:wrap;
		@include module.tab{
			padding: 8px 3px 0;
		}
		&__link{
			display: block;
			&:hover{
				.img-wrap{
					.on{
						opacity: 1;
					}
				}
			}
		}
		&__item{
	    @include module.pc{
	      &:nth-of-type(-n+2){
	      	width: 49%;
	      	margin-right: 2%;
	      }
	      &:nth-of-type(2){
	      	margin-right: 0;
	      }
	      &:nth-of-type(n+3){
		      width: 32.3%;
		      margin-right: 1.55%;
		      margin-top: 56px;
	      }
	      &:nth-of-type(3n+5){
	        margin-right: 0;
	      }
	    }
	    @include module.tab{
	    	width: 100%;
	    	&:nth-of-type(n+2){
	    		margin-top: 45px;
	    	}
	    }
	    .name{
	    	font-size: 1.6rem;
	    	font-weight: bold;
	    	letter-spacing: 0.1em;
	    	margin-bottom: 15px;
	    	border-left: solid 4px module.$bl;
	    	padding: 1px 10px;
	    }
	    .img-wrap{
	    	text-align: center;
	    	border: solid 1px #8A8A8A;
	    	position: relative;
	    	.on{
	    		position: absolute;
	    		top:0;
	    		bottom:0;
	    		z-index: 1;
	    		opacity: 0;
	    	}
	    }
      .link-row{
      	margin-top: 14px;
        letter-spacing: 0.1em;
        text-align: right;
      }
		}
	}

	/*　ARTIST詳細　ここから　*/
	.artist{
		width: 720px;
		max-width:90%;
		margin: 0 auto 90px;
		@include module.sp{
			width: 315px;
		}
		&__img-wrap{
			text-align: center;
			margin-bottom: 15px;
		}
		&__txt-wrap{
			font-size: 1.6rem;
			letter-spacing: 0.1em;
			line-height: calc(28 / 16);
			margin-bottom: 27px;
			@include module.tab{
				margin-bottom: 23px;
			}
		}
		&__link-row{
			display: flex;
			justify-content: space-between;
			margin-bottom: 43px;
			.link-arrow{
				letter-spacing: 0.1em;
			}
			.sns{
				&__link{
					&:nth-of-type(n+2){
						margin-left: 10px;
					}
					img{
						max-height:30px;
					}
				}
			}
			@include module.tab{
				display: block;
				.link-arrow{
					margin-bottom: 13px;
				}
			}
		}
	}
	/*　ARTIST詳細　ここまで　*/

	/*　TALENT詳細　ここから　*/
	.talent{
	  width: 960px;
	  max-width: 90%;
	  margin: 0 auto 100px;
	  display: flex;
	  justify-content: space-between;
	  @include module.tab{
	  	display: block;
	  }
	  @include module.sp{
	    width: 315px;
	  }
	  &__left{
	  	width: 44%;
	  	@include module.tab{
	  		width: 100%;
	  		margin-bottom: 9px;
	  	}
	  }
	  &__slider{
	  	.slick-prev,
	  	.slick-next{
	  		width: 32px;
	  		height: 32px;
	  		border: solid 1px module.$bl;
	  		border-radius: 50%;
	  		top:calc(50% - 9px);
	  		z-index: 99;
	  		&:before{
	  			content:"";
	  			display: block;
	  			position: absolute;
	  			width: 10px;
	  			height: 10px;
	  			border-top: solid 1px module.$bl;
	  			border-right: solid 1px module.$bl;
	  			opacity: 1;
	  			top:50%;
	  		}
	  	}
	  	.slick-prev{
	  		left:0;
	  		&:before{
	  			transform: translateY(-50%) rotate(-135deg);
	  			left:12px;
	  		}
	  	}
	  	.slick-next{
	  		right:0;
	  		&:before{
	  			transform: translateY(-50%) rotate(45deg);
	  			right:12px;
	  		}
	  	}
	  }
	  &__slide{
	  	padding: 0 40px 15px;
	  	img{
	  		margin: 0 auto;
	  		box-shadow: 8px 3px 12px rgba(0,0,0,.16);
	  	}
	  }
	  &__right{
	  	width: 52%;
	  	@include module.tab{
	  		width: 100%;
	  	}
	  }
	  &__ttl{
	  	margin-bottom: 13px;
	  	.kana{
	  		font-size: 1.6rem;
	  		vertical-align:baseline;
	  		letter-spacing: 0.1em;
	  	}
	  	@include module.tab{
	  		.kana{
	  			font-size: 1.4rem;
	  		}
	  	}
	  }
	  &__txt-wrap{
	  	line-height: calc(24 / 14);
	  	letter-spacing: 0.1em;
	  	margin-bottom: 24px;
	  }
	  &__dt{
	  	margin-bottom: 5px;
	  }
	  &__dd{
	  	&+.artist__dt{
	  		margin-top: 25px;
	  	}
	  }
  	.txt{
	  	line-height: calc(24 / 14);
	  	letter-spacing: 0.1em;
  	}
  	.sns{
  		margin-left: -4.5%;
  		&__link{
  			display: inline-block;
  			margin: 0 0 5px 4.5%;
  		}
  	}
  	.link-arrow{
  		font-size: 1.6rem;
  		letter-spacing: 0.1em;
  		.arrow{
  			top: 6px;
  		}
  	}
  	@include module.tab{
  		&__txt-wrap{
  			margin-bottom: 20px;
  		}
  		&__dd{
  			&+.artist__dt{
  				margin-top: 19px;
  			}
  		}
  		.sns{
  			margin-left: -5%;
  			&__link{
  				margin: 0 0 5px 5%;
  			}
  			&__ic{
  				height: 32px;
  			}
  		}
  	}
	}
	/*　TALENT詳細　ここまで　*/
}

/*　ARTIST / TALENT　ここまで　*/


/*　ABOUT　ここから　*/

.main--about{
	margin-top: 23px;
	@include module.tab{
		margin-top: 21px;
	}
	.under-ttl{
		margin-bottom: 14px;
		@include module.tab{
			margin-bottom: 23px;
		}
	}
	.about{
		width: 760px;
		max-width:90%;
		margin: 0 auto 100px;
		@include module.tab{
			margin-bottom: 80px;
		}
	  @include module.sp{
	    width: 315px;
	  }
	}
}

/*　ABOUT　ここまで　*/


/*　CONTACT　ここから　*/

.form-wrap{
	.confirm-txt{
		display: none;
	}
	.btn-row{
		margin-top: 40px;
		text-align: center;
		.btn-wrap{
			max-width:100%;
			display: inline-block;
		}
		.btn{
			display: block;
			width: 100%;
		  font-size: 1.6rem;
		  letter-spacing: 0.1em;
		  font-family: module.$lora;
		  font-weight: module.$medium;
		  color: module.$bl;
		  background: #F4F4F4;
		  border: solid 1px module.$bl;
		  border-radius:100px;
		  line-height: calc(22 / 16);
		  padding: 23px 67px 23px 23px;
		  position: relative;
		  cursor: pointer;
		  transition:.3s ease;
		  &--back{
		  	background: #fff;
		  }
		  &:hover{
		    background: module.$bl;
		    color: #fff;
		  }
		  @include module.tab{
		    padding: 18px 70px 18px 20px;
		    .arrow{
		      width: 30px;
		      height: 30px;
		      &:before,
		      &:after{
		        right: 8px;
		      }
		    }
		  }
		}
	}
}

.main--contact{
	margin-top: 23px;
	@include module.tab{
		margin-top: 21px;
	}
	.under-ttl{
		margin-bottom: 42px;
		@include module.tab{
			margin-bottom: 45px;
		}
	}
	.form-wrap{
		width: 720px;
		max-width:90%;
		margin: 0 auto 100px;
		@include module.tab{
			margin-bottom: 80px;
		}
	  @include module.sp{
	    width: 315px;
	  }
		input,
		textarea,
		select{
			font-family: module.$noto;
			border: solid 1px #D8D8D8;
			box-sizing:border-box;
			padding-left: 1rem;
			outline:none;
			border-radius:8px;
			&::placeholder {
			  color: #C8C8C8;
				font-size: 1.4rem;
				font-weight: module.$light;
			}
		}
		input[type="text"],
		input[type="email"]{
			height: 50px;
			width: 100%;
		}
		textarea{
			width: 100%;
			height: 230px;
			padding: 1rem;
			resize: none;
		}
		.select-wrap{
			position: relative;
			width: 47%;
			&:before{
				content:"";
				display: block;
				position: absolute;
				right:14px;
				top:calc(50% - 6px);
				width: 6px;
				height: 6px;
				transform:rotate(45deg);
				border-bottom: solid 2px module.$bl;
				border-right: solid 2px module.$bl;
				pointer-events: none;
			}
			select{
				width: 100%;
				height: 50px;
				cursor: pointer;
				-webkit-appearance: none;/* ベンダープレフィックス(Google Chrome、Safari用) */
				-moz-appearance: none; /* ベンダープレフィックス(Firefox用) */
				appearance: none; /* 標準のスタイルを無効にする */
				font-size: 1.5rem;
				font-weight: normal;
			}
			::-ms-expand {
				display: none;
			}
			@include module.tab{
				width: 100%;
			}
		}
		input[type="submit"],
		input[type="button"] {
		  border-radius: 0;
		  -webkit-box-sizing: content-box;
		  -webkit-appearance: button;
		  appearance: button;
		  border: none;
		  box-sizing: border-box;
		  &::-webkit-search-decoration {
		    display: none;
		  }
		  &::focus {
		    outline-offset: -2px;
		  }
		}
		.dl{
			&+.dl{
				margin-top: 41px;
			}
			.dt{
				padding-bottom: 12px;
				&__txt{
					font-size: 1.6rem;
					font-weight: bold;
					vertical-align:middle;
				}
				.required{
					display: inline-block;
					font-size: 1.2rem;
					font-family: module.$sans;
					text-align: center;
					padding: 4px 10px;
					margin-left: 10px;
					border: solid 1px #171717;
					vertical-align:middle;
				}
			}
			.dd{
				display: flex;
				justify-content: space-between;
				flex-wrap:wrap;
			}
			.input-wrap{
				width: 100%;
				&--half{
					width: 48%;
				}
				&--row2{
					&+.input-wrap--row2{
						margin-top: 10px;
					}
				}
			}
		}
		.btn-row{
			margin-top: 40px;
		}
	}
}

.main--confirm{
	margin-top: 23px;
	@include module.tab{
		margin-top: 21px;
	}
	.under-ttl{
		margin-bottom: 44px;
	}
	.confirm{
		width: 720px;
		max-width:90%;
		margin: 0 auto 100px;
		@include module.tab{
			margin-bottom: 80px;
		}
	  @include module.sp{
	    width: 315px;
	  }
	  &__ttl{
	  	margin-bottom: 31px;
	  	@include module.tab{
	  		margin-bottom: 21px;
	  	}
	  }
	}
	.form-wrap{
	  .dl{
	  	display: flex;
	  	justify-content: space-between;
  		font-size: 1.6rem;
  		line-height: calc(28 / 16);
	  	&+.dl{
	  		margin-top: 24px;
	  	}
	  	.dt{
	  		width: 26%;
	  		&__txt{
	  			font-weight: bold;
	  		}
	  		.required{
	  			display: none;
	  		}
	  	}
	  	.dd{
	  		width: 73%;
	  	}
	  	.input-wrap--row2{
	  		&+.input-wrap--row2{
	  			display: none;
	  		}
	  	}
	  	@include module.tab{
	  		display: block;
	  		.dt{
	  			width: 100%;
	  		}
	  		.dd{
	  			width: 100%;
	  			font-size: 1.4rem;
	  			line-height: 2;
	  		}
	  	}
	  }
	  .confirm-txt{
	  	display: block;
	  	text-align: center;
	  	font-size: 1.6rem;
	  	line-height: calc(28 / 16);
	  	margin: 57px 0;
	  	@include module.tab{
	  		margin: 36px 0;
	  	}
	  }
	  .btn-row{
	  	margin-top: 0;
	  	.btn-wrap{
	  		width: 200px;
	  		margin: 0 20px;
	  		&--confirm{
	  			width: 0;
	  			margin: 0;
	  		}
	  	}
	  	.btn{
	  		padding-left: 5px;
	  		padding-right: 5px;
	  	}
	  	@include module.tab{
	  		display: flex;
	  		justify-content: center;
	  		.btn-wrap{
	  			margin: 0;
	  			max-width:47%;
	  			&--back{
	  				margin-right: 6%;
	  			}
	  		}
	  	}
	  }
	}
}

.main--completion{
	margin-top: 23px;
	@include module.tab{
		margin-top: 21px;
	}
	.under-ttl{
		margin-bottom: 44px;
	}
	.completion{
		width: 720px;
		max-width:90%;
		margin: 0 auto 100px;
		@include module.tab{
			margin-bottom: 80px;
		}
	  @include module.sp{
	    width: 315px;
	  }
	  &__ttl{
	  	margin-bottom: 37px;
	  }
	  &__txt-wrap{
	  	text-align: center;
	  	margin-bottom: 37px;
	  	.txt{
	  		font-size: 1.6rem;
	  		line-height: calc(28 / 16);
	  		&+.txt{
	  			margin-top: 28px;
	  		}
	  	}
	  }
	  &__btn-row{
	  	text-align: center;
	  	.btn-arrow{
	  		width: 200px;
	  	}
	  }
	}
}

/*　CONTACT　ここまで　*/


/*　AUDITION　ここから　*/

.main--audition{
	margin-top: 23px;
	@include module.tab{
		margin-top: 21px;
	}
	.under-ttl{
		margin-bottom: 43px;
	}
	.audition{
		width: 960px;
		max-width:90%;
		margin: 0 auto 100px;
		@include module.tab{
			margin-bottom: 80px;
		}
	  @include module.sp{
	    width: 315px;
	  }
	  .ttl-border{
	  	margin-bottom: 27px;
	  	@include module.tab{
	  		margin-bottom: 24px;
	  	}
	  }
	  .btn-row{
	  	display: flex;
	  	flex-wrap:wrap;
	  	justify-content: center;
	  	margin-top:50px;
	  	.btn-arrow{
	  		margin: 0 10px 20px;
				width: 320px;
				padding-left: 30px;
	  	}
	  	@include module.tab{
	  		flex-direction:column;
	  		align-items: center;
	  		margin-top: 37px;
	  		.btn-arrow{
  				width: 295px;
	  			margin: 0;
	  			&+.btn-arrow{
	  				margin-top: 20px;
	  			}
	  		}
	  	}
	  }
	  .sec{
	  	&+.sec{
	  		margin-top: 100px;
	  	}
	  	@include module.tab{
	  		&+.sec{
	  			margin-top: 78px;
	  		}
	  	}
	  }
		&__intro{
			text-align: center;
			margin-bottom: 95px;
			.logo-wrap{
				margin-bottom: 40px;
			}
			.img-wrap{
				margin-bottom: 50px;
			}
			.lead{
				font-weight: module.$black;
				font-size: 4.8rem;
				letter-spacing: 0.1em;
				margin-bottom: 47px;
			}
			.txt{
				font-size: 1.6rem;
				letter-spacing: 0.1em;
				line-height: calc(36 / 16);
			}
			@include module.tab{
				margin-bottom: 77px;
				.logo-wrap{
					img{
						max-width:312px;
					}
				}
				.img-wrap{
					margin-bottom: 45px;
				}
				.lead{
					font-size: 2.4rem;
					margin-bottom: 42px;
				}
				.txt{
					font-size: 1.4rem;
					line-height: 2;
				}
			}
		}
		.method{
			&__txt-wrap{
				&+.ttl-bg{
					margin-top: 40px;
				}
				.btn-row{
					margin: 36px 0 59px;
				}
				@include module.tab{
					.btn-row{
						margin: 32px 0 40px;
					}
				}
			}
			&__row{
				display: flex;
				justify-content: space-between;
				margin-bottom: 14px;
				@include module.tab{
					flex-direction: column-reverse;
					.qr-wrap{
						text-align: center;
						margin-bottom: 16px;
					}
				}
			}
			.txt{
				letter-spacing: 0.1em;
				line-height: calc(24 / 14);
				&+.txt{
					margin-top: 24px;
				}
				&+.list-indent{
					margin-top: 12px;
				}
			}
			.list-indent{
				line-height: calc(24 / 14);
				@include module.tab{
					font-size: 1.4rem;
				}
			}
			.link-row{
				letter-spacing: 0.1em;
				margin-top: 18px;
				&+.link-row{
					margin-top: 15px;
				}
				.ttl{
					letter-spacing: 0;
					font-size: 1.6rem;
					vertical-align:baseline;
					margin-right: 8px;
				}
			}
			.boxed{
				.ttl{
					font-family: module.$lora;
					font-size: 1.6rem;
					letter-spacing: 0.1em;
					font-weight: bold;
					line-height: calc(28 / 16);
					margin-bottom: 5px;
				}
			}
		}
		.message{
			&__box{
				@include module.pc{
					padding-right: 2%;
				}
				&+.message__box{
					margin-top: 30px;
				}
				&+.ttl-bg{
					margin-top: 40px;
				}
			}
			.info-row{
				display: flex;
				flex-wrap:wrap;
				align-items: center;
				margin-bottom: 12px;
				.name{
					font-size: 1.8rem;
					font-weight: bold;
					letter-spacing: 0.1em;
					line-height: calc(28 / 18);
					margin-bottom: 2px;
				}
				.cat-row{
					margin-left: 5px;
				}
	      .cat{
	      	font-family: module.$sans;
	      	text-align: center;
	        letter-spacing: 0.1em;
	        color: #fff;
	        background: module.$bl;
	        border-radius:100px;
	        padding: 3px 5px 4px;
	        display: inline-block;
	        margin: 0 2px 2px;
	        &__txt{
	        	@include module.fz9;
	        }
	      }
 			}
 			&__row{
 				display: flex;
 				justify-content: space-between;
 				margin-bottom: 38px;
 				.txt-wrap{
 					width: 63%;
 				}
 				.img-wrap{
 					width: 36%;
 					text-align: right;
 				}
 				@include module.tab{
 					flex-direction:column-reverse;
 					.txt-wrap{
 						width: 100%;
 					}
 					.img-wrap{
 						width: 100%;
 						text-align: center;
 						margin-bottom: 20px;
 					}
 				}
 			}
			.txt{
				letter-spacing: 0.1em;
				line-height: calc(24 / 14);
				&+.txt{
					margin-top: 24px;
				}
			}
			.ttl-left{
				margin-bottom: 5px;
			}
		}
	}
}

/*　AUDITION　ここまで　*/


/*　RECRUIT　ここから　*/

.main--recruit{
	margin-top: 23px;
	@include module.tab{
		margin-top: 21px;
	}
	.under-ttl{
		margin-bottom: 43px;
	}
	.recruit{
		width: 960px;
		max-width:90%;
		margin: 0 auto 100px;
		@include module.tab{
			margin-bottom: 80px;
		}
	  @include module.sp{
	    width: 315px;
	  }
	  .ttl-border{
	  	margin-bottom: 27px;
	  	@include module.tab{
	  		margin-bottom: 24px;
	  	}
	  }
	  .btn-row{
	  	text-align: center;
	  	margin-top: 40px;
	  	.btn-arrow{
	  		width: 320px;
	  		padding-left: 73px;
	  	}
	  	@include module.tab{
	  		.btn-arrow{
	  			width: 295px;
	  			text-align: center;
	  			padding-left: 45px;
	  			padding-right: 45px;
	  		}
	  	}
	  }
	}
	.job{
		&__inner{
			width: 760px;
			max-width:100%;
			margin: 0 auto;
		}
		&__img-wrap{
			text-align: center;
			@include module.tab{
				margin-bottom: 5px;
			}
		}
    .ttl-bg{
    	margin-bottom: 0;
    }
		&__dl{
			&+.ttl-bg{
				margin-top: 40px;
			}
		}
    .tag-list{
      margin: 30px -2px 33px;
	    &__item{
	    	font-family: module.$sans;
	      font-size: 1.1rem;
	      font-weight: module.$medium;
	    	text-align: center;
	      letter-spacing: 0.1em;
	      color: #fff;
	      background: module.$bl;
	      border-radius:100px;
	      padding: 5px 8px;
	      display: inline-block;
	      margin: 0 2px 8px;
	    }
	    @include module.tab{
	    	margin: 25px -2px 33px;
	    	&__item{
	    		font-size: 1rem;
	    		margin: 0 2px 7px;
	    	}
	    }
    }
    .map{
    	margin-top: 18px;
    	padding-bottom: 55.5%;
    	@include module.tab{
    		margin-top: 4px;
    	}
    }
    .txt{
    	&+.txt{
    		margin-top: 28px;
    	}
    }
    .table-decision{
    	&__td{
    		font-size: 1.6rem;
    		line-height: calc(28 / 16);
    		letter-spacing: 0.1em;
    		vertical-align:top;
    		&:first-of-type{
    			text-align: right;
    		}
    		&:nth-of-type(2){
    			padding: 0 15px;
    		}
    	}
  		.gy{
  			color: #D8D8D8;
  		}
  		@include module.tab{
  			&__td{
  				font-size: 1.4rem;
  				line-height: 2;
  			}
  		}
    }
	}
}

/*　RECRUIT　ここまで　*/


/*　PRIVACY POLICY　ここから　*/

.main--pp{
	margin-top: 23px;
	@include module.tab{
		margin-top: 21px;
	}
	.under-ttl{
		margin-bottom: 73px;
		@include module.tab{
			margin-bottom: 37px;
		}
	}
	.pp{
		width: 960px;
		max-width:90%;
		margin: 0 auto 100px;
		@include module.tab{
			margin-bottom: 80px;
		}
	  @include module.sp{
	    width: 315px;
	  }
	  &__lead{
			letter-spacing: 0.1em;
			line-height: calc(24 / 14);
			margin-bottom: 60px;
	  }
	  &__dl{
	  	margin-bottom: 40px;
	  }
	  .list-indent{
	  	@include module.tab{
	  		padding-left: 0;
	  	}
	  }
	  &__dt{
	  	margin-bottom: 10px;
	  }
	  &__dd{
			letter-spacing: 0.1em;
			line-height: calc(24 / 14);
			margin-bottom: 40px;
	  }
	  &__date{
	  	text-align: right;
	  }
	}
}

/*　PRIVACY POLICY　ここまで　*/

